import { PdfModel } from '@/models/pdf-model'
import { AxiosResponse } from 'axios'

export default class PdfHelpers {
  public static GeneratePDFModel (docBaseElementRefs: any, contentClasses: string,
    contentMarginBottom?: number, contentMarginTop?: number, contentMarginLeft?: number, contentMarginRight?: number,
    headerMaxHeight?: number, footerMaxHeight?: number,
    headerForceMinHeight?: number, footerForceMinHeight?: number) {
    const contentWrapper = `<div class="${contentClasses}"><div class="doc-content-box">$PLACEHOLDER$</div></div>`
    const headerFooterWrapper = "$PLACEHOLDER$"
    const model: PdfModel = {
      headerFooterWrapper: headerFooterWrapper,
      contentWrapper: contentWrapper,
      header: docBaseElementRefs.theheader.outerHTML,
      footer: docBaseElementRefs.thefooter.outerHTML,
      content: docBaseElementRefs.thecontent.outerHTML,
      wrapHeader: true,
      wrapFooter: false,
      wrapContent: true,
      addHeadToHeaderFooter: true,
      contentMarginBottom: contentMarginBottom,
      contentMarginTop: contentMarginTop,
      contentMarginLeft: contentMarginLeft,
      contentMarginRight: contentMarginRight,
      headerMaxHeight: headerMaxHeight,
      footerMaxHeight: footerMaxHeight,
      headerForceMinHeight: headerForceMinHeight,
      footerForceMinHeight: footerForceMinHeight
    }
    return model
  }

  private static getParameterCaseInsensitive (object: any, key: any) {
    return object[Object.keys(object).find(k => k.toLowerCase() === key.toLowerCase())!]
  }

  public static downloadFile (response: AxiosResponse<any>) {
    const blob = new Blob([response.data], { type: response.data.type })
    const url = window.URL.createObjectURL(blob)
    const link = document.createElement("a")
    link.href = url
    const contentDisposition = PdfHelpers.getParameterCaseInsensitive(
      response.headers,
      "Content-Disposition"
    )
    let fileName = 'test.pdf'
    if (contentDisposition) {
      const fileNameMatch = contentDisposition.match(/filename="?(.+)"?/)
      if (fileNameMatch != null && fileNameMatch.length === 2) {
        fileName = fileNameMatch[1]
        // content disposition filename is usually url encoded
        fileName = fileName.replace(/\+/g, "%20")
        fileName = decodeURIComponent(fileName)
      }
    }
    link.setAttribute("download", fileName)
    document.body.appendChild(link)
    link.click()
    setTimeout(() => {
      link.remove()
      window.URL.revokeObjectURL(url)
    }, 100)
  }
}
