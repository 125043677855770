import { DocumentAdminPdfModel } from '@/models/pdf-model'
import { ErrorService } from '@/services/error.service'
import { DocumentAdminService } from '@/services/patient-document-admin-service'
import VueRouter from 'vue-router'
import PdfHelper from './PdfHelpers'

export default class ReportHelpers {
  public static GeneratePDFModel (typeId: number, fileName: string, docPreviewElement: any, prescriptionId: string, doctorName: string, appointmentId?: number) {
    const model: DocumentAdminPdfModel = {
      ...PdfHelper.GeneratePDFModel(docPreviewElement.$refs.docbase.$refs, 'doc-preview-a4', 5, 35, 0, 0, 60, 20, 180, 100),
      typeId: typeId,
      targetFileName: fileName,
      prescriptionId: prescriptionId,
      appointmentId: appointmentId,
      doctorFullName: doctorName
    }
    return model
  }

  public static async UploadDocument (model: DocumentAdminPdfModel, errorMessages: string[], router: VueRouter, id: string, openNewTab = false) {
    const documentAdminService = DocumentAdminService.getInstance()
    await documentAdminService.uploadDocumentAdmin(model, id).then(() => {
      if (openNewTab) {
        const routeData = router.resolve({ name: 'DocumentAdmin', params: { id: id } })
        window.open(routeData.href, '_blank')
      } else {
        router.push({ name: 'DocumentAdmin' })
      }
    }).catch(async (errs) => {
      const res = await ErrorService.handleError(errs)
      errorMessages.length = 0
      res.errors.forEach(error => {
        errorMessages.push(error)
      })
    })
  }
}
