import http from '@/http-client'
import { DocumentAdminInfoResponse } from '@/models/document-admin-model'
import { DocumentAdminPdfModel } from '@/models/pdf-model'

export class DocumentAdminService {
  private static instance: DocumentAdminService

  public static getInstance (): DocumentAdminService {
    if (!DocumentAdminService.instance) {
      DocumentAdminService.instance = new DocumentAdminService()
    }
    return DocumentAdminService.instance
  }

  // ==> documentsadmin
  public async uploadDocumentAdmin (document: DocumentAdminPdfModel, prescriptionId: string) {
    const response = await http.post(`/dossierPatient/documentsadmin/${prescriptionId}`, document)
    return response.data as string
  }

  public async getPatientDocumentAdmin (patientId: string) {
    const response = await http.get(`/dossierPatient/documentsadmin/${patientId}`)
    return response.data as DocumentAdminInfoResponse[]
  }

  public async deletePatientDocumentAdmin (id?: string) {
    if (id) {
      const res = await http.delete(`/dossierPatient/documentsadmin/${id}`)
      return res.status as number
    }
  }
}
